import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import MoonLoader from 'react-spinners/MoonLoader';
/* --- import Components ----*/
import Layout from 'src/components/Layout';
import { Section } from '../../components/Section';
import { FormSteps } from 'src/components/EventForm';
import { RecruitersSection } from 'src/components/Partners';
import { HeroHomeWrapper } from 'src/components/Hero/elements';
import LandingPageBottomFormSection from 'src/components/EventForm/LandingPageBottomFormSection';
import HeroText from 'src/components/LandingPage/HeroText';
import { MainVideo } from 'src/components/Projects';
import TestimonialsSectionLandingPage from 'src/components/LandingPage/TestimonialsSectionLandingPage';
import ButtonScrollToForm from 'src/components/LandingPage/ButtonScrollToForm';
import FinancingLandingPage from 'src/components/LandingPage/FinancingLandingPage';
import FeaturesSection from 'src/components/Bootcamp/FeaturesSection';
/* --- import Utils ----*/
import { formattingSelectedEvent } from 'src/components/EventForm/formattingEvents';
/* --- import custom hook ----*/
import { useFutureDataFromMeetup } from 'src/utils/useFutureDataFromMeetup';
/* --- import Styles ----*/
import colors from 'src/style/colors';

const ReunionInfoLanding = ({ location, pageContext: { dataFromMeetup } }) => {
  const [formCompleted, setFormCompleted] = useState(false);
  const [isLoading, futureDataFromMeetup] = useFutureDataFromMeetup(dataFromMeetup);
  const imageData = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "2023_students.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      image2: file(relativePath: { eq: "2023_students6.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      image3: file(relativePath: { eq: "2023_studentsAndTeacher.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      video: file(relativePath: { eq: "2023_studentsAndTeacher3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C"
      pageName="Inscription à la réunion d'information"
      pageType="landingPage"
      metaTitle="Le Reacteur | Inscrivez-vous à l'une de nos réunions d'information"
      metaDescription="Découvrez notre formation intensive en développement Web et Mobile JavaScript & React. Rejoignez notre réunion d'information pour démarrer votre parcours dès aujourd'hui !"
      css={{ paddingTop: 35 }}
    >
      {/* FIRST BANNER => form */}
      <Section theme="primary" hero landing>
        <div css={{ paddingBottom: 0 }}>
          <HeroHomeWrapper landing>
            <HeroText fromPage="reunion" />
            {isLoading ? (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '50%',
                }}
              >
                <MoonLoader color={colors.white} size={20} />
              </div>
            ) : (
              <FormSteps
                dataFromMeetup={futureDataFromMeetup}
                selectedEvent={formattingSelectedEvent(futureDataFromMeetup[0])}
                pageType="landingPage"
                formCompleted={formCompleted}
                setFormCompleted={setFormCompleted}
              />
            )}
          </HeroHomeWrapper>
        </div>
      </Section>
      {/* SECOND BANNER => video */}
      <Section landing>
        <MainVideo
          mainThumbnail={imageData.video.childImageSharp.fluid}
          link="https://www.youtube.com/embed/59XH_-eMQhE"
          loopRef="59XH_-eMQhE"
          noModal
        />
        <ButtonScrollToForm text={`Je participe à la réunion d'information\u00A0!`} />
      </Section>
      {/* THIRD BANNER => Testimonials */}
      <TestimonialsSectionLandingPage fromPage="reunion" />
      {/* FOURTH BANNER => Icons */}
      <FeaturesSection
        fromPage="reunion"
        landing
        images={[
          imageData.image1.childImageSharp.gatsbyImageData,
          imageData.image2.childImageSharp.gatsbyImageData,
          imageData.image3.childImageSharp.gatsbyImageData,
        ]}
      />
      {/* FIFTH BANNER => Companies */}
      <RecruitersSection theme="secondary" landing fromPage="reunion" />
      {/* SIXTH BANNER => Financements */}
      <FinancingLandingPage fromPage="reunion" />
      {/* SEVENTH BANNER => Form + photo */}
      <Section theme="primary" landing>
        {isLoading ? (
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <MoonLoader color={colors.white} size={20} />
          </div>
        ) : (
          <LandingPageBottomFormSection
            dataFromMeetup={futureDataFromMeetup}
            formattedNextEvent={formattingSelectedEvent(futureDataFromMeetup[0])}
            formCompleted={formCompleted}
            setFormCompleted={setFormCompleted}
          />
        )}
      </Section>
    </Layout>
  );
};

export default ReunionInfoLanding;
